import React from 'react';

import PanelModelItem from './PanelModelItem';

const PanelModels = (props) => {
    if (props.items.length === 0) {
        return (
          <>
            <h3>No results!</h3>
          </>
        );
      }
    
      return (
            <div className="models">
            {props.items.map((model, index) => (
              <PanelModelItem
                key={index}
                id={index}
                image={model.image}
                nick={model.nick}
                name={model.name}
                buttons={model.buttons}
                socials={model.socials}
                onDelete={props.onDeleteModel}
                onUpdate={props.onUpdateModel}
              />
            ))}
            </div>
      );
}

export default PanelModels;