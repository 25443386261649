import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Row, Container, Col, Button } from 'react-bootstrap';

import axios from 'axios'
import Loader from './Loader';

const ModelPage = () => {
    const { nick } = useParams();
    const [models, setModels] = useState();
    const [model, setModel] = useState();
    const [isLoading, setIsLoading] = useState(false)

    const fetchModels = async () => {        
        try {
            const response = await axios.get('https://gorillamediallc.com/asp/stored/data/models_list.json');
            const result = response.data.sort(function() { 
                return .5 - Math.random() 
            });

            setModels(result);
        } catch (err) {
            console.log(err)
        }
    }

    const fetchModel = async () => {
        try {
            const response = await axios.get('https://gorillamediallc.com/asp/stored/data/models_list.json');
            const result = response.data.filter((item) => item.nick === nick)

            setModel(result[0])
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        setIsLoading(true)
        fetchModels()
        fetchModel()

        setTimeout(() => {
            setIsLoading(false)
        }, 2500);
    }, [nick]);

    return (
        <>
        <div className="model-bg">
        <Container style={{ minHeight: "100vh", height: "auto" }}>
            {isLoading && <Loader />}

            {!isLoading && !model && (
                <>
                <div className="global-loader">
                    <img src={`${process.env.PUBLIC_URL}/images/noresults.png`} style={{ maxHeight: "250px" }} alt="noresults" />
                </div>
                </>
            )}

            {!isLoading && model && models && (
                <>
                <Row>
                    <Col sm={8}>
                        <div className="modelpage">
                            <center>
                            <img src={model.image} className="img-fluid img-thumbnail model-image shadow-sm" style={{ maxHeight: "200px" }} alt={model.name} />
                            <h4>{model.name}</h4>
                            </center>

                            {!model.buttons && !model.socials && (
                                                <>
                                                <div className="addsomecss_custom text-center">
                                                    <hr />
                                                    <img src={`${process.env.PUBLIC_URL}/images/noresults.png`} style={{ maxHeight: "120px" }} alt="noresults" />
                                                    <p>No additional information for this profile!</p>
                                                </div>
                                                </>
                            )}

                            <div className="modelpage-buttons">
                            {model && model.buttons && model.buttons.map((button, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <a href={button.button_redirection} target="_blank" rel="noreferrer">
                                            <Button variant="light" className="w-100 shadow-sm"> {button.button_text} </Button>
                                        </a>
                                    </React.Fragment>
                                )
                            })}
                            </div>

                            <div className="modelpage-socials">
                                <center>
                                {model && model.socials && model.socials.map((social, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                        {social.type === "facebook" ? (
                                            <>
                                            <div className="d-inline-block m-1">
                                                <a href={social.redirect_url} target="_blank" rel="noreferrer">
                                                <Button variant="dark"><i className='fa-brands fa-facebook-square'></i></Button>
                                                </a>
                                            </div>
                                            </>
                                        ) : social.type === "twitter" ? (
                                            <>
                                            <div className="d-inline-block m-1">
                                                <a href={social.redirect_url} target="_blank" rel="noreferrer">
                                                <Button variant="dark"><i className='fa-brands fa-twitter-square'></i></Button>
                                                </a>
                                            </div>
                                            </>
                                        ) : social.type === "instagram" ? (
                                            <>
                                            <div className="d-inline-block m-1">
                                                <a href={social.redirect_url} target="_blank" rel="noreferrer">
                                                <Button variant="dark"><i className='fa-brands fa-instagram-square'></i></Button>
                                                </a>
                                            </div>
                                            </>                                            
                                        ) : social.type === "spotify" ? (
                                            <>
                                            <div className="d-inline-block m-1">
                                                <a href={social.redirect_url} target="_blank" rel="noreferrer">
                                                <Button variant="dark"><i className="fa-brands fa-spotify"></i></Button>
                                                </a>
                                            </div>
                                            </>       
                                        ) : social.type === "linkedin" ? (
                                            <>
                                            <div className="d-inline-block m-1">
                                                <a href={social.redirect_url} target="_blank" rel="noreferrer">
                                                <Button variant="dark"><i className="fa-brands fa-linkedin"></i></Button>
                                                </a>
                                            </div>
                                            </>                                             
                                        ) : social.type === "email" ? (
                                            <>
                                            <div className="d-inline-block m-1">
                                                <a href={social.redirect_url} rel="noreferrer">
                                                <Button variant="dark"><i className="fa-solid fa-inbox"></i></Button>
                                                </a>
                                            </div>
                                            </>      
                                        ) : null}
                                        </React.Fragment>
                                    )
                                })}
                                </center>
                            </div>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="white-block-generalf">
                            <div className="wbg-title">Models</div>

                            <hr />

                            <div className="overflow-auto" style={{ height: "700px" }}>
                            {models && models.map((model) => {
                                return (
                                    <React.Fragment key={model.nick}>
                                    <Link to={`/model/${model.nick}`}>
                                    <Row>
                                        <Col sm={4}>
                                            <img src={model.image} className="img-fluid img-thumbnail" style={{ objectFit: "cover" }} alt={model.nick} />
                                        </Col>
                                        <Col sm={8}>
                                            <h3>{model.nick}</h3>
                                            <p>{model.name}</p>
                                        </Col>
                                    </Row>
                                    </Link>
                                    </React.Fragment>
                                )
                            })}
                            </div>
                        </div>
                    </Col>
                </Row>                
                </>
            )}
        </Container>
        </div>
        </>
    )
}

export default ModelPage;