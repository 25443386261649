import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

import { Navbar } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import Home from './components/Home';
import ModelPage from './components/ModelPage';
import Panel from './components/Panel';
import Services from './components/Services';

function App() {
  let routes = (
  <Routes>
    <Route path="/*" element={<Home />}/>
    <Route path="/model/:nick" exact element={<ModelPage />}/>
    <Route path="/panel" element={<Panel />}/>
    <Route path="*" element={<Home />}/>
  </Routes>
  );

  return (
    <>
    <Navbar className="topnav">
        <Navbar.Brand className="topnav-brand" href="/">
        AUTOSHOUT
        </Navbar.Brand>
    </Navbar>

    <Router>
        {routes}
    </Router>
    </>
  );
}

export default App;
