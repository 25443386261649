import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Container, Row, Col, Button } from 'react-bootstrap';

import axios from 'axios'

import Loader from './Loader';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Home = () => {
    const [models, setModels] = useState();
    const [isLoading, setIsLoading] = useState(false)

    const fetchModels = async () => {
        setIsLoading(true)
        
        try {
            const response = await axios.get('https://gorillamediallc.com/asp/stored/data/models_list.json');
            const result = response.data.sort(function() { 
                return .5 - Math.random() 
            }).slice(0, 12);

            setModels(result);

            setTimeout(() => {
                setIsLoading(false)
            }, 2500);
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchModels()
    }, []);

    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

    return (
        <>
        <Container fluid>

        {isLoading && <Loader />}

        {!isLoading && (
        <Row className="row-landing">
        <Col sm={5} className="left-landing">
        </Col>
        <Col sm={7}>
            <div style={{ margin: "2rem" }}>
            <h1>OUR MODELS</h1>
            <Slider {...settings}>
            {!isLoading && models && models.map((model, index) => {
                return (
                    <React.Fragment key={index}>
                        <div>
                            <Link to={`../model/${model.nick}`}>
                            <div className="user-block shadow-sm">

                                <div className="user-block-image">
                                    <img src={model.image} className="img-fluid w-100 h-100" style={{ minHeight: "256px", maxHeight: "256px", objectFit: 'cover' }} alt={model.name} />
                                </div>

                                <div className="user-block-content">
                                <p>{model.name}</p>

                                <hr />

                                <Button variant="primary" className="w-100"> VIEW PROFILE </Button>
                                </div>
                            </div>
                            </Link>
                        </div>
                    </React.Fragment>
                )
            })}
            </Slider>
            </div>

            
            {/*             
            <hr />

            <div className="service-block position-relative">
                  <Row>
                    <Col sm={8}>
                      <h1>Services</h1>
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                      <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                      <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.</p>
                      <Link to="/services">
                        <Button variant="primary" className="button-glow">See all offers</Button>
                      </Link>
                    </Col>

                    <Col sm={4}>
                    <div className="service-block-gradient">
                      <h1>
                        <i className="fa-solid fa-arrow-up-right-dots"></i>
                      </h1>
                    </div>
                    </Col>

                  </Row>
            </div> */}

        </Col>
        </Row>
        )}
        </Container>
        </>
    )
}

export default Home;