import React, { useState, useEffect } from 'react';

import PanelModels from './PanelModels';
import { Container, Form, Button, Modal } from 'react-bootstrap';

import axios from 'axios';

const Panel = () => {
    const [password, setPassword] = useState();
    const [accesed, setAccesed] = useState(false);


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const submitForm = async (e) => {
        e.preventDefault();

        try {
            const responseData = await axios.post(
                'https://gorillamediallc.com/asp/api/panelauth',
                {
                    password: password
                },
                {headers: {
                   Authorization: 'Auth ' + password,
                  'Content-Type': 'application/json'
                }}
            );  

            setAccesed(responseData.data.response)
            alert(responseData.data.message)
            if(responseData.data.response === true) localStorage.setItem('password', password)
        } catch (err) {
            console.log('err' + err)
        }
    }

    const [displayBtnForm, setDisplayBtnForm] = useState(false)
    const [displaySocialForm, setDisplaySocialForm] = useState(false);

    const [image, setImage] = useState();
    const [nick, setNick] = useState();
    const [name, setName] = useState();    

    const [buttons, setButtons] = useState([]);
    const [socials, setSocials] = useState([]);

    const [buttonTitle, setButtonTitle] = useState();
    const [buttonRedirect, setButtonRedirect] = useState();

    const [socialType, setSocialType] = useState();
    const [socialRedirection, setSocialRedirection] = useState();

    const [serverData, setServerData] = useState([]);

    const fetchData = async () => {
        await axios.get('https://gorillamediallc.com/asp/stored/data/models_list.json')
        .then(function (response) {
            setServerData(response.data);
        })
        .catch(function (error) {
          console.log(error);
        })
    }

    useEffect(() => {
        fetchData()
    }, [serverData]);

    const addButton = () => {
        const object = {
            "button_text": buttonTitle,
            "button_redirection": buttonRedirect
        }

        if(buttonTitle && buttonRedirect){ 
            setButtons(buttons => [...buttons, object]);
    
            setButtonTitle('')
            setButtonRedirect('')
    
            setDisplayBtnForm(false)
          } else {
            alert('All fields are required!')
          }
    }

    const deleteButton = (id) => {
        const result = buttons.filter((button, index) => index !== id);
        setButtons(result)
    }

    const addSocial = () => {
        const object = {
            "type": socialType,
            "redirect_url": socialRedirection
        }

        if(socialType && socialRedirection){ 
            setSocials(socials => [...socials, object]);
    
            setSocialType('')
            setSocialRedirection('')
    
            setDisplaySocialForm(false)
        } else {
            alert('All fields are required!')
        }
    }

    const deleteSocial = (id) => {
        const result = socials.filter((social, index) => index !== id);
        setSocials(result)
    }

    const saveFile = async (data) => {
        try {
          await axios.post(
            'https://gorillamediallc.com/asp/api/savefile',
            data,
            {headers: {
                Authorization: 'Auth ' + localStorage.getItem('password'),
               'Content-Type': 'application/json'
            }}
          );
    
        } catch (err) {
          console.log(err)
        }
    }


    const addModel = () => {

        if(!image || !nick || !name){
            alert('All fields are required!')
            return;
        }

        const formObject = {
            "image": image,
            "nick": nick,
            "name": name,
            "buttons": buttons,
            "socials": socials
        }

        const check = serverData.filter((item) => item.nick === nick);
        if(check.length > 0) {
            alert('Nick already exist!')
            return;
        }

        serverData.push(formObject)
        saveFile(serverData)

        setImage('')
        setName('')
        setNick('')
        setButtons([])
        setSocials([])

        handleClose()       

        fetchData()
    }

    const modelDeleteHandler = deletedModelId => {
        const result = serverData.filter((item) => item.nick !== deletedModelId)

        setServerData(result)
        saveFile(result)
        fetchData()
    };

    const modelUpdateHandler = () => {
        fetchData()
    }

    return (
        <>
        <Container>
        
        {!accesed ? (
            <>
            <div className="login-form">
            <Form onSubmit={submitForm}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlPass">
                    <Form.Label>Password:</Form.Label>
                    <Form.Control onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password" />
                </Form.Group>
                <Button type="submit" variant="dark"> Login </Button>
            </Form>
            </div>
            </>
        ) : (
            <>
            <Modal show={show} size="lg" onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Add Model</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {displayBtnForm === false ? (
                        <>
                        <Button variant="dark" className="mr-2" onClick={() => setDisplayBtnForm(true)}> Add Button </Button>
                        </>
                    ) : (
                        <>
                        <Button variant="danger" className="mr-2" onClick={() => setDisplayBtnForm(false)}> Close </Button>
                        </>
                    )}

                    {displaySocialForm === false ? (
                        <>
                        <Button variant="dark" className="m-2" onClick={() => setDisplaySocialForm(true)}> Add Social Profile </Button>
                        </>
                    ) : (
                        <>
                        <Button variant="danger" className="m-2" onClick={() => setDisplaySocialForm(false)}> Close </Button>
                        </>
                    )}
                    
                    {displayBtnForm === true && (
                        <>
                        <div className="panel-btnform">
                            <Form.Group className="mb-3" controlId="exampleForm.ControlBtn1">
                                <Form.Label>Button title:</Form.Label>
                                <Form.Control type="text" onChange={(e) => setButtonTitle(e.target.value)} />
                            </Form.Group>      
                            <Form.Group className="mb-3" controlId="exampleForm.ControlBtn2">
                                <Form.Label>Button redirect:</Form.Label>
                                <Form.Control type="text" onChange={(e) => setButtonRedirect(e.target.value)} />
                            </Form.Group>     

                            <Button variant="success" onClick={() => addButton()}> Add Button </Button>                  
                        </div>
                        </>
                    )}

                    {displaySocialForm === true && (
                        <>
                        <div className="panel-btnform">
                            <Form.Group className="mb-3" controlId="exampleForm.ControlBtn1">
                                <Form.Label>Type:</Form.Label>
                                <Form.Select onChange={(e) => setSocialType(e.target.value)} aria-label="Default select example">
                                <option>Select social type</option>
                                <option value="facebook">facebook</option>
                                <option value="twitter">twitter</option>
                                <option value="instagram">instagram</option>
                                <option value="spotify">spotify</option>
                                <option value="linkedin">linkedin</option>
                                <option value="email">email</option>
                                </Form.Select>
                            </Form.Group>      
                            <Form.Group className="mb-3" controlId="exampleForm.ControlBtn2">
                                <Form.Label>Redirect:</Form.Label>
                                <Form.Control onChange={(e) => setSocialRedirection(e.target.value)} type="text" />
                            </Form.Group>     

                            <Button variant="success" onClick={() => addSocial()}> Add Social </Button>                  
                        </div>
                        </>
                    )}

                    <hr />

                    <Form.Group className="mb-3" controlId="exampleForm.ControlImage">
                            <Form.Label>Image: <small>(Image URL)</small></Form.Label>
                            <Form.Control type="text" onChange={(e) => setImage(e.target.value)} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlNick">
                            <Form.Label>Nick:</Form.Label>
                            <Form.Control type="text" onChange={(e) => setNick(e.target.value)} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlNick">
                            <Form.Label>Name:</Form.Label>
                            <Form.Control type="text" onChange={(e) => setName(e.target.value)} />
                    </Form.Group>

                    <Button variant="success" size="lg" onClick={() => addModel()}> Save model </Button>

                    <hr />

                    {buttons && buttons.length > 0 && (
                    <>    
                        <h3>Buttons ( {buttons.length} )</h3>
                    </>
                    )}

                    {buttons && buttons.map((button, index) => {
                            return (
                                <React.Fragment key={index}>
                                <div className="btn-block">
                                    <Button variant="danger" size="sm" className="float-end" onClick={() => deleteButton(index)}> delete </Button>
                                    <b>{button.button_text}</b> <br /> {button.button_redirection}
                                </div>
                                </React.Fragment>
                            )
                    })}


                    {socials && socials.length > 0 && (
                    <>    
                        <h3>Social Profiles ( {socials.length} )</h3>
                    </>
                    )}

                    {socials && socials.map((social, index) => {
                            return (
                                <React.Fragment key={index}>
                                <div className="btn-block">
                                    <Button variant="danger" size="sm" className="float-end" onClick={() => deleteSocial(index)}> delete </Button>
                                    <b>{social.type}</b> <br /> {social.redirect_url}
                                </div>
                                </React.Fragment>
                            )
                    })}

                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>

            <div className="panel-body">
                <Button variant="success" className="float-end" onClick={handleShow}> Add Model </Button>
                <h2>ASP - Panel</h2>

                <hr />

                {serverData && <PanelModels items={serverData} onDeleteModel={modelDeleteHandler} onUpdateModel={modelUpdateHandler} />}
            </div>
            </>
        )}
        </Container>
        </>
    )
}

export default Panel;