import React, { useState, useEffect } from 'react';

import { Row, Col, Button, Modal, Form } from 'react-bootstrap';

import axios from 'axios';

const PanelModelItem = (props) => {
    const [serverData, setServerData] = useState();
    const [globalData, setGlobalData] = useState();

    const [image, setImage] = useState();
    const [nick, setNick] = useState();
    const [name, setName] = useState();
    const [buttons, setButtons] = useState();
    const [socials, setSocials] = useState();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const deleteModel = (id) => {
        props.onDelete(id);
    }

    const editModel = async (id) => {
        handleShow()

        await axios.get('https://gorillamediallc.com/asp/stored/data/models_list.json')
        .then(function (response) {
            setGlobalData(response.data)
            const result = response.data.filter((item) => item.nick === id)
            setServerData(result);

            setImage(result[0].image)
            setNick(result[0].nick)
            setName(result[0].name)

            setButtons(result[0].buttons)
            setSocials(result[0].socials)
        })
        .catch(function (error) {
          console.log(error);
        })        
    }

    const deleteButton = (id) => {
        const result = buttons.filter((button, index) => index !== id);
        setButtons(result)
    }

    const deleteSocial = (id) => {
        const result = socials.filter((social, index) => index !== id);
        setSocials(result)
    }

    const saveFile = async (data) => {
        try {
          await axios.post(
            'https://gorillamediallc.com/asp/api/savefile',
            data,
            {headers: {
                Authorization: 'Auth ' + localStorage.getItem('password'),
               'Content-Type': 'application/json'
            }}
          );
    
        } catch (err) {
          console.log(err)
        }
    }

    const saveModel = async () => {
        let objIndex = globalData.findIndex((obj => obj.nick === props.nick));
                    
        globalData[objIndex] = {
              "image": image,
              "nick": nick,
              "name": name,
              "buttons": buttons,
              "socials": socials
        }
                          
        saveFile(globalData);
        handleClose()

        props.onUpdate()
    }

    const [displayBtnForm, setDisplayBtnForm] = useState(false)
    const [displaySocialForm, setDisplaySocialForm] = useState(false);

    const [buttonTitle, setButtonTitle] = useState();
    const [buttonRedirect, setButtonRedirect] = useState();

    const [socialType, setSocialType] = useState();
    const [socialRedirection, setSocialRedirection] = useState();

    const addButton = () => {
        const object = {
            "button_text": buttonTitle,
            "button_redirection": buttonRedirect
        }

        if(buttonTitle && buttonRedirect){ 
            setButtons(buttons => [...buttons, object]);
    
            setButtonTitle('')
            setButtonRedirect('')
    
            setDisplayBtnForm(false)
          } else {
            alert('All fields are required!')
          }
    }

    const addSocial = () => {
        const object = {
            "type": socialType,
            "redirect_url": socialRedirection
        }

        if(socialType && socialRedirection){ 
            setSocials(socials => [...socials, object]);
    
            setSocialType('')
            setSocialRedirection('')
    
            setDisplaySocialForm(false)
        } else {
            alert('All fields are required!')
        }
    }

    return (
        <React.Fragment key={props.id}>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Edit Model</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {serverData && (
                        <>
                        {displayBtnForm === false ? (
                            <>
                            <Button variant="dark" className="mr-2" onClick={() => setDisplayBtnForm(true)}> Add Button </Button>
                            </>
                        ) : (
                            <>
                            <Button variant="danger" className="mr-2" onClick={() => setDisplayBtnForm(false)}> Close </Button>
                            </>
                        )}

                        {displaySocialForm === false ? (
                            <>
                            <Button variant="dark" className="m-2" onClick={() => setDisplaySocialForm(true)}> Add Social Profile </Button>
                            </>
                        ) : (
                            <>
                            <Button variant="danger" className="m-2" onClick={() => setDisplaySocialForm(false)}> Close </Button>
                            </>
                        )}
                        
                        {displayBtnForm === true && (
                            <>
                            <div className="panel-btnform">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlBtn1">
                                    <Form.Label>Button title:</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setButtonTitle(e.target.value)} />
                                </Form.Group>      
                                <Form.Group className="mb-3" controlId="exampleForm.ControlBtn2">
                                    <Form.Label>Button redirect:</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setButtonRedirect(e.target.value)} />
                                </Form.Group>     

                                <Button variant="success" onClick={() => addButton()}> Add Button </Button>                  
                            </div>
                            </>
                        )}

                        {displaySocialForm === true && (
                            <>
                            <div className="panel-btnform">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlBtn1">
                                    <Form.Label>Type:</Form.Label>
                                    <Form.Select onChange={(e) => setSocialType(e.target.value)} aria-label="Default select example">
                                    <option>Select social type</option>
                                    <option value="facebook">facebook</option>
                                    <option value="twitter">twitter</option>
                                    <option value="instagram">instagram</option>
                                    <option value="spotify">spotify</option>
                                    <option value="linkedin">linkedin</option>
                                    <option value="email">email</option>
                                    </Form.Select>
                                </Form.Group>      
                                <Form.Group className="mb-3" controlId="exampleForm.ControlBtn2">
                                    <Form.Label>Redirect:</Form.Label>
                                    <Form.Control onChange={(e) => setSocialRedirection(e.target.value)} type="text" />
                                </Form.Group>     

                                <Button variant="success" onClick={() => addSocial()}> Add Social </Button>                  
                            </div>
                            </>
                        )}

                        <Form.Group className="mb-3" controlId="exampleForm.ControlImage">
                                <Form.Label>Image: <small>(Image URL)</small></Form.Label>
                                <Form.Control type="text" value={image} onChange={(e) => setImage(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlNick">
                                <Form.Label>Nick:</Form.Label>
                                <Form.Control type="text" value={nick} onChange={(e) => setNick(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlNick">
                                <Form.Label>Name:</Form.Label>
                                <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} />
                        </Form.Group>        

                        {buttons && buttons.length > 0 && (
                        <>    
                            <h3>Buttons ( {buttons.length} )</h3>
                        </>
                        )}

                        {buttons && buttons.map((button, index) => {
                                return (
                                    <React.Fragment key={index}>
                                    <div className="btn-block">
                                        <Button variant="danger" size="sm" className="float-end" onClick={() => deleteButton(index)}> delete </Button>
                                        <b>{button.button_text}</b> <br /> {button.button_redirection}
                                    </div>
                                    </React.Fragment>
                                )
                        })}


                        {socials && socials.length > 0 && (
                        <>    
                            <h3>Social Profiles ( {socials.length} )</h3>
                        </>
                        )}

                        {socials && socials.map((social, index) => {
                                return (
                                    <React.Fragment key={index}>
                                    <div className="btn-block">
                                        <Button variant="danger" size="sm" className="float-end" onClick={() => deleteSocial(index)}> delete </Button>
                                        <b>{social.type}</b> <br /> {social.redirect_url}
                                    </div>
                                    </React.Fragment>
                                )
                        })}                

                        <hr />

                        <Button variant="success" size="lg" onClick={() => saveModel()}>Save Model</Button>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>

            <Row className="panelmodel-item">
                <Col sm={1}>
                    <img src={props.image} className="img-thumbnail img-fluid" alt={props.name} />
                </Col>
                <Col sm={7}>
                    <label>Name:</label> {props.name} <br />
                    <label>Nick:</label> {props.nick} <br />
                    <label>Image:</label> <a href={props.image} target="_blank" rel="noreferrer">{props.image}</a>
                </Col>
                <Col sm={3}>
                    <label>Buttons:</label> {props.buttons.length} <br />
                    <label>Socials:</label> {props.socials.length}
                </Col>
                <Col sm={1}>
                <Button variant="warning" size="sm" onClick={() => editModel(props.nick)}> edit </Button> <br />
                <Button variant="danger" className="mt-1" size="sm" onClick={() =>  deleteModel(props.nick)}> delete </Button>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default PanelModelItem;