import React from 'react';

const Loader = () => {
    return (
        <>
            <div className="global-loader">
                <img src={`${process.env.PUBLIC_URL}/images/loader.gif`} alt="loader" />
            </div>
        </>
    )
}

export default Loader;